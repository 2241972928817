<template>
  <main class="max-w-screen-xl container mx-auto">
    <slot></slot>
  </main>
</template>

<script>


export default {
  name: "Layout",
  components: {
  }
}
</script>

<style scoped>
</style>
