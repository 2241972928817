<template>
    <div>
        <b-table striped hover :items="users"></b-table>
    </div>
</template>

<script>
import axios from "axios";

export default ({
    name: "IndividualLeaderboard",
    async created() {
        let response = await axios.get("StravaData/AllActivity");
        if (response.status === 200) {
            console.log(response);
        }

        this.users = [
            {
                Id: 1,
                FirstName: "Alice",
                LastName: "Johnson",
                GroupName: "Swimming Club",
                SportActivities: [
                    {
                        Name: "Lap Swimming",
                        Time: 60,
                        Distance: 1.5,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Water Aerobics",
                        Time: 45,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 2,
                FirstName: "Bob",
                LastName: "Wilson",
                GroupName: "Cycling Club",
                SportActivities: [
                    {
                        Name: "Road Cycling",
                        Time: 120,
                        Distance: 20,
                        PhotoCount: 2,
                    },
                    {
                        Name: "Mountain Biking",
                        Time: 90,
                        Distance: 15,
                        PhotoCount: 1,
                    },
                ],
            },
            {
                Id: 3,
                FirstName: "Charlie",
                LastName: "Davis",
                GroupName: "Basketball Club",
                SportActivities: [
                    {
                        Name: "Pickup Game",
                        Time: 120,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Shooting Practice",
                        Time: 60,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 4,
                FirstName: "David",
                LastName: "Brown",
                GroupName: "Horseback Riding Club",
                SportActivities: [
                    {
                        Name: "Trail Riding",
                        Time: 180,
                        Distance: 8,
                        PhotoCount: 3,
                    },
                    {
                        Name: "Horse Show",
                        Time: 120,
                        Distance: 0,
                        PhotoCount: 5,
                    },
                ],
            },
            {
                Id: 5,
                FirstName: "Eva",
                LastName: "Garcia",
                GroupName: "Tennis Club",
                SportActivities: [
                    {
                        Name: "Singles Match",
                        Time: 90,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Doubles Match",
                        Time: 120,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 6,
                FirstName: "Frank",
                LastName: "Lee",
                GroupName: "Swimming Club",
                SportActivities: [
                    {
                        Name: "Lap Swimming",
                        Time: 75,
                        Distance: 2,
                        PhotoCount: 1,
                    },
                    {
                        Name: "Water Polo",
                        Time: 90,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 7,
                FirstName: "Grace",
                LastName: "Chen",
                GroupName: "Cycling Club",
                SportActivities: [
                    {
                        Name: "Road Cycling",
                        Time: 90,
                        Distance: 15,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Indoor Cycling",
                        Time: 60,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 8,
                FirstName: "Canyon",
                LastName: "Schwartz",
                GroupName: "Lacrosse Club",
                SportActivities: [
                    {
                        Name: "Game",
                        Time: 125,
                        Distance: 20,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Practice",
                        Time: 69,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 9,
                FirstName: "Frankie",
                LastName: "Houlbrooke",
                GroupName: "Ice Skating Club",
                SportActivities: [
                    {
                        Name: "Practice",
                        Time: 30,
                        Distance: 20,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Practice",
                        Time: 45,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
        ];
        
        this.sortUsersByActivityTime();
    },
    data() {
        return {
            users: []
        }
    },
    methods: {
        formatTime(time) {
            const hours = Math.floor(time / 60);
            const minutes = time - (hours * 60);
            const hourText = hours === 0 ? '' : hours === 1 ? '1 hour' : `${hours} hours`;
            const minuteText = minutes === 0 ? '' : minutes === 1 ? '1 minute' : `${minutes} minutes`;

            return `${hourText} ${minuteText}`;
        },
        sortUsersByActivityTime() {
            const usersWithTotalTime = this.users.map((user) => ({
                FirstName: user.FirstName,
                LastName: user.LastName,
                GroupName: user.GroupName,
                TotalActivityTime: user.SportActivities.reduce(
                  (total, activity) => total + activity.Time,
                  0
                ),
            }));

            // Sort the users by total activity time in descending order
            const sortedUsers = usersWithTotalTime.sort(
              (a, b) => b.TotalActivityTime - a.TotalActivityTime
            );

            this.users = sortedUsers.map((user) => ({
                FirstName: user.FirstName,
                LastName: user.LastName,
                GroupName: user.GroupName,
                TotalActivityTime: this.formatTime(user.TotalActivityTime),
            }));
        }
    }
})
</script>

<style scoped>

</style>