import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import {clickOutside} from "@/directives/clickOutside";
import BootstrapVueNext from 'bootstrap-vue-next'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrophy, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

function callbackRedirect(appState) {
  return router.push(appState && appState.targetUrl ? appState.targetUrl : "/")
}

const main = async () => {
  let app = createApp(App).use(router, callbackRedirect());

  app.use(BootstrapVueNext);
  
  library.add(faTrophy, faClock);
  
  app.component('font-awesome-icon', FontAwesomeIcon);
  
  app.directive("clickOutside", clickOutside);
  
  return app.mount("#app");
}

// Execute async function
/* eslint-disable-next-line no-unused-vars */
main().then(() => {
  console.debug("App Mounted");
});
