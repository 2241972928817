const clickOutside = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                if(typeof binding.value === "function")
                    binding.value();
                else
                    console.error("clickOutside must be bound to function")
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};

export { clickOutside }
