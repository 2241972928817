import {createWebHistory, createRouter} from "vue-router"
import NotFound from "@/components/pages/NotFound.vue"
import Home from "@/components/pages/Home"
import IndividualLeaderboard from "@/components/pages/IndividualLeaderboard.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/:pathMatch(.*)",
        component: NotFound
    },
    {
        path: "/individual",
        name: "Individual",
        component: IndividualLeaderboard
    }
]

const router = createRouter({
    linkExactActiveClass: 'active',
    // History mode, vs Hash mode
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return {}
        }
        // The vue router docs are apparently wrong, and say this should be { x: 0, y: 0 }, which doesn't work.
        // https://stackoverflow.com/questions/54535838/scroll-behaviour-vuejs-not-working-properly
        return {left: 0, top: 0}
    },
})

export default router
