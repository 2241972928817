<template>
    <b-card-group deck class="pt-5">
        <b-card v-for="(team, index) in topThreeTeams" body-class="text-center" class="w-25"
                :style="{borderColor: trophyColor(index)}">
            <b-card-body>
                <b-avatar src="https://picsum.photos/300/300" size="7rem" badge-variant="light">
                    <template #badge>
                        <font-awesome-icon icon="fa-sold fa-trophy" :style="{color: trophyColor(index)}"/>
                    </template>
                </b-avatar>
                <h4 class="pt-4">
                    {{ team.name }}
                </h4>
            </b-card-body>
            <b-card-body>
                <h5>
                    <font-awesome-icon icon="fa-solid fa-clock"/>
                    <span class="card-time-text">{{ formatTime(team.totalTime) }}</span>
                </h5>
            </b-card-body>
        </b-card>
    </b-card-group>

    <b-container class="pt-4">
        <b-row no-gutters v-for="(team, index) in otherTeams" class="pt-3 pb-3 mt-5 mb-5 text-center">
            <b-col>
                <span class="lower-place-text">{{ index + 4 }}th</span>
            </b-col>
            <b-col cols="8">
                <div>
                    <b-avatar src="https://placekitten.com/300/300" size="35px"></b-avatar>
                    <span class="lower-team-name">{{ team.name }}</span>
                </div>
            </b-col>
            <b-col>
                <font-awesome-icon icon="fa-solid fa-clock" class="lower-time-icon"/>
                <span class="lower-time-text">{{ formatTime(team.totalTime) }}</span>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import axios from "axios";

export default {
    name: "Home",
    components: {},
    async created() {
        let response = await axios.get("StravaData/AllActivity");
        if (response.status === 200) {
            console.log(response);
        }

        this.users = [
            {
                Id: 1,
                FirstName: "Alice",
                LastName: "Johnson",
                GroupName: "Swimming Club",
                SportActivities: [
                    {
                        Name: "Lap Swimming",
                        Time: 60,
                        Distance: 1.5,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Water Aerobics",
                        Time: 45,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 2,
                FirstName: "Bob",
                LastName: "Wilson",
                GroupName: "Cycling Club",
                SportActivities: [
                    {
                        Name: "Road Cycling",
                        Time: 120,
                        Distance: 20,
                        PhotoCount: 2,
                    },
                    {
                        Name: "Mountain Biking",
                        Time: 90,
                        Distance: 15,
                        PhotoCount: 1,
                    },
                ],
            },
            {
                Id: 3,
                FirstName: "Charlie",
                LastName: "Davis",
                GroupName: "Basketball Club",
                SportActivities: [
                    {
                        Name: "Pickup Game",
                        Time: 120,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Shooting Practice",
                        Time: 60,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 4,
                FirstName: "David",
                LastName: "Brown",
                GroupName: "Horseback Riding Club",
                SportActivities: [
                    {
                        Name: "Trail Riding",
                        Time: 180,
                        Distance: 8,
                        PhotoCount: 3,
                    },
                    {
                        Name: "Horse Show",
                        Time: 120,
                        Distance: 0,
                        PhotoCount: 5,
                    },
                ],
            },
            {
                Id: 5,
                FirstName: "Eva",
                LastName: "Garcia",
                GroupName: "Tennis Club",
                SportActivities: [
                    {
                        Name: "Singles Match",
                        Time: 90,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Doubles Match",
                        Time: 120,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 6,
                FirstName: "Frank",
                LastName: "Lee",
                GroupName: "Swimming Club",
                SportActivities: [
                    {
                        Name: "Lap Swimming",
                        Time: 75,
                        Distance: 2,
                        PhotoCount: 1,
                    },
                    {
                        Name: "Water Polo",
                        Time: 90,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 7,
                FirstName: "Grace",
                LastName: "Chen",
                GroupName: "Cycling Club",
                SportActivities: [
                    {
                        Name: "Road Cycling",
                        Time: 90,
                        Distance: 15,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Indoor Cycling",
                        Time: 60,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 8,
                FirstName: "Canyon",
                LastName: "Schwartz",
                GroupName: "Lacrosse Club",
                SportActivities: [
                    {
                        Name: "Game",
                        Time: 125,
                        Distance: 20,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Practice",
                        Time: 69,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
            {
                Id: 9,
                FirstName: "Frankie",
                LastName: "Houlbrooke",
                GroupName: "Ice Skating Club",
                SportActivities: [
                    {
                        Name: "Practice",
                        Time: 30,
                        Distance: 20,
                        PhotoCount: 0,
                    },
                    {
                        Name: "Practice",
                        Time: 45,
                        Distance: 0,
                        PhotoCount: 0,
                    },
                ],
            },
        ];
    },
    data() {
        return {
            users: []
        }
    },
    methods: {
        trophyColor(place) {
            return place === 0 ? 'gold' : place === 1 ? 'silver' : place === 2 ? '#cd7f32' : '';
        },
        formatTime(time) {
            const hours = Math.floor(time / 60);
            const minutes = time - (hours * 60);
            const hourText = hours === 0 ? '' : hours === 1 ? '1 hour' : `${hours} hours`;
            const minuteText = minutes === 0 ? '' : minutes === 1 ? '1 minute' : `${minutes} minutes`;

            return `${hourText} ${minuteText}`;
        },
        getGroupsTotalTime() {
            const totalTimeByTeam = {};
            for (const {GroupName, SportActivities} of this.users) {
                const totalSportActivityTime = SportActivities.reduce((total, {Time}) => total + Time, 0);
                totalTimeByTeam[GroupName] = (totalTimeByTeam[GroupName] || 0) + totalSportActivityTime;
            }

            return totalTimeByTeam;
        }
    },
    computed: {
        topThreeTeams() {
            const totalTimeByTeam = this.getGroupsTotalTime();

            return Object.entries(totalTimeByTeam)
                .sort(([, time1], [, time2]) => time2 - time1)
                .slice(0, 3)
                .map(([team, totalTime]) => ({name: team, totalTime}));
        },
        otherTeams() {
            const totalTimeByTeam = this.getGroupsTotalTime();

            return Object.entries(totalTimeByTeam)
                .sort(([, time1], [, time2]) => time2 - time1)
                .slice(3)
                .map(([team, totalTime]) => ({name: team, totalTime}));
        }
    }
}
</script>

<style scoped>
.card, .row {
    background-color: #ef9343;
    border-radius: 15px;
    border-width: 4px;
}

.card-time-text {
    padding-left: 8px;
}

.gold-trophy {
    color: gold;
}

.silver-trophy {
    color: silver;
}

.bronze-trophy {
    color: #cd7f32;
}

.lower-team-name {
    padding-left: 8px;
    font-size: 20px;
}

.lower-place-text {
    font-size: 20px;
}

.lower-time-text {
    font-size: 20px;
}

.lower-time-icon {
    font-size: 20px;
    padding-right: 12px;
}
</style>
