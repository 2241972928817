/*
 * Given an anchor hash string (ex: #element), scrolls to that element.
 * It explicitly sets x to 0 in window.scrollTo to ensure the window doesn't jump
 * to the side when an element isn't left-aligned.
 *
 * Usage:
 *
 * You should use router-link to hash option to create anchor links, rather than html a tags.
 * `<router-link :to="{ name: this.$route.name, hash: '#anchorid'}">Click here</router-link>`
 *
 * In the vue component containing anchor links, watch the route to call our custom scroll method.
```
export default {
  name: "MyComponent",
  // ...
  watch:{
    $route (to, from) {
      if (to.hash) {
        scrollToAnchor(to.hash)
      }
    }
  }
}
```
 */
function scrollToAnchor(hash) {
  const id = hash.slice(1) // remove initial '#'
  const target = document.querySelector(`[id='${id}']`)
  window.scrollTo(0, target.offsetTop)
}

export { scrollToAnchor }
