<template>
    <div>
        <div>
            <b-navbar toggleable="lg" variant="light">
                <b-navbar-brand href="#">
                    <img src="@/assets/bombora_logo-Orange-2022.png" class="bombora-logo">
                </b-navbar-brand>

                <b-navbar-nav start>
                    <b-nav-item @click="this.$router.push({name: 'Home'})">Group Leaderboard</b-nav-item>
                    <b-nav-item @click="this.$router.push({name: 'Individual'})">Individual Leaderboard</b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav end class="ml-auto">
                    <b-nav-item right href="http://www.strava.com/oauth/authorize?client_id=106703&response_type=code&redirect_uri=https://stravaliveapp.gcp.bommsandbox.in/logincallback&approval_prompt=force&scope=profile:read_all,activity:read_all,read">
                        Opt In
                    </b-nav-item>
                </b-navbar-nav>
            </b-navbar>
        </div>
    </div>
    <Layout>
        <router-view></router-view>
    </Layout>
</template>

<script>
import {scrollToAnchor} from "@/utils/scroll"
import Layout from "@/components/layout/Layout";

export default {
    name: "App",
    components: {
        Layout
    },
    data() {
    },
    mounted: function () {
        // This is only called when the SPA is initially loaded, which is a case that
        // router.scrollPosition doesn't cover.
        // It ensures that, if the url contains an anchor #, the page scrolls down to that hash.
        if (this.$route.hash) {
            setTimeout(() => scrollToAnchor(this.$route.hash), 1)
        }
    },
    methods: {
        scrollFix: function (hashbang) {
            location.hash = hashbang
        },
    },
}
</script>

<style scoped>
.bombora-logo {
    height: 37px;
}

.nav-item {
    font-size: 20px;
}
</style>
